var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"command"},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visib,
    intersection: {
      threshold: _vm.threshold,
      },
    throttle: _vm.throttle,
    once: true,
    }),expression:"{\n    callback: visib,\n    intersection: {\n      threshold,\n      },\n    throttle,\n    once: true,\n    }"}]}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.promptTxt)}}),(!_vm.seen)?_c('vue-typer',{attrs:{"text":" ","repeat":Infinity,"preTypeDelay":999999999,"preEraseDelay":9999999999,"initial-action":"typing","caret-animation":"blink","type-delay":_vm.typeDelay}}):_vm._e(),(_vm.seen && _vm.nores && !_vm.erased)?_c('vue-typer',{attrs:{"text":_vm.cmdtxt,"repeat":0,"initial-action":"typing","caret-animation":"blink","type-delay":_vm.typeDelay,"eraseOnComplete":true,"preEraseDelay":200},on:{"erased":_vm.onErased}}):_vm._e(),(_vm.seen && !_vm.nores)?_c('vue-typer',{attrs:{"text":_vm.cmdtxt,"repeat":0,"initial-action":"typing","caret-animation":"blink","type-delay":_vm.typeDelay},on:{"typed":_vm.onTyped}}):_vm._e(),_c('br'),(_vm.typedCmd || _vm.overridden2)?_c('span',{staticClass:"result"},[_vm._t("default")],2):_vm._e(),_c('br'),(_vm.typedCmd && _vm.showLastPrompt)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.promptTxt)}}):_vm._e(),(_vm.typedCmd && _vm.showLastPrompt)?_c('vue-typer',{attrs:{"text":" ","repeat":Infinity,"preTypeDelay":999999999,"preEraseDelay":9999999999,"initial-action":"typing","caret-animation":"blink","type-delay":0}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }